import { CheckCircleFilled, CheckCircleOutlined } from '@ant-design/icons';
import { Alert, Select, Space, Table, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

import './ChangeLog.less';

const formatMessage = (message) => {
  const re = /([^#]*)#([0-9]+)([^#]*)/g;
  const parts = [];
  let msgString = message;

  for (let part = []; part; part = re.exec(message)) {
    if (part.length > 0) parts.push(part);
  }

  if (parts.length !== 0)
    msgString = parts
      .map(
        (part) =>
          `${part[1]}[#${
            part[2]
          }](${`${process.env.REACT_APP_SHORTCUT_BASE_URL}/circularco/story/${part[2]}`})${
            part[3]
          }`
      )
      .join('');

  return (
    <pre className="commit-msg">
      <ReactMarkdown>{msgString}</ReactMarkdown>
    </pre>
  );
};

const relevantBranches = {
  dev: 'red',
  main: 'green',
  demo: 'blue'
};

function Release({ succeeded, branches }) {
  return (
    <div className="release">
      {(succeeded && <CheckCircleFilled />) || undefined}
    </div>
  );
}

Release.propTypes = {
  succeeded: PropTypes.bool,
  branches: PropTypes.array
};

function DateCell({ date, branches }) {
  return (
    <div className="date-cell">
      <Space direction="vertical">
        {date}
        <Space wrap="auto">
          {branches.map((branch) => (
            <Tag key={branch} color={relevantBranches[branch]}>
              {branch}
            </Tag>
          ))}
        </Space>
      </Space>
    </div>
  );
}

DateCell.propTypes = {
  date: PropTypes.string,
  branches: PropTypes.array
};

function ChangeLog() {
  const { owner, repo, branch } = useParams();
  const [list, setList] = useState([]);
  const [error, setError] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const branches = Object.keys(relevantBranches);
  const branchArrayString = encodeURIComponent(JSON.stringify(branches));
  const loadList = async () => {
    const url = `${process.env.REACT_APP_API_URL}/changelog/${owner}/${repo}/${branch}?branches=${branchArrayString}`;
    const response = await fetch(url);
    if (response.status === 200) {
      setError(false);
      const json = await response.json();
      setList(json.results);
      setError(false);
      setLoaded(true);
    } else {
      setError(true);
      setLoaded(false);
      setList([]);
    }
  };

  useEffect(() => {
    loadList();
    const timer = setInterval(() => {
      loadList();
    }, 300000);
    return () => clearInterval(timer);
  }, []);

  const columns = [
    {
      title: 'Released',
      dataIndex: 'succeeded',
      key: 'tag',
      align: 'center',
      // width: 100,
      render: (o, record) => (
        <Release succeeded={o} branches={record.other_branches} />
      )
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      // width: 200,
      render: (o, record) => (
        <DateCell date={o} branches={record.other_branches} />
      )
    },
    {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (o) => formatMessage(o)
    },
    {
      title: 'Committer',
      dataIndex: 'committer',
      key: 'committer'
    }
  ];

  return (
    <div className="changelog">
      <div className="changelog-dropdown">
        <label htmlFor="changelog-select">Changelog:</label>
        <Select
          id="changelog-select"
          value={`${owner}/${repo}/${branch}`}
          onSelect={(clPath) => {
            window.location.href = `/changelog/${clPath}`;
          }}
          options={[
            {
              label: 'API DEV',
              value: 'circular-exchange/circular-django/dev'
            },
            {
              label: 'API PROD',
              value: 'circular-exchange/circular-django/main'
            },
            {
              label: 'API DEMO',
              value: 'circular-exchange/circular-django/demo'
            },
            {
              label: 'App DEV',
              value: 'circular-exchange/circular-react/dev'
            },
            {
              label: 'App PROD',
              value: 'circular-exchange/circular-react/main'
            },
            {
              label: 'App DEMO',
              value: 'circular-exchange/circular-react/demo'
            },
            {
              label: 'Scrappy DEV',
              value: 'circular-exchange/circular-scrappy/dev'
            },
            {
              label: 'Scrappy PROD',
              value: 'circular-exchange/circular-scrappy/main'
            },
            {
              label: 'Scrappy DEMO',
              value: 'circular-exchange/circular-scrappy/demo'
            }
          ]}
        />
      </div>
      {error && <Alert message="Error loading change log" type="error" />}
      <Table
        pagination={{ pageSize: 50 }}
        rowClassName={(record) =>
          record.succeeded ? 'released' : 'not-released'
        }
        loading={!loaded}
        columns={columns}
        dataSource={list}
      />
    </div>
  );
}

export default ChangeLog;
