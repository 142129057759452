import './Util/logger-init';
import React from 'react';
import ReactDOM from 'react-dom';
// import { Amplify } from 'aws-amplify';
// import { Helmet } from 'react-helmet';
import App from './App';
import reportWebVitals from './reportWebVitals';
// import * as serviceWorker from './serviceWorker';

// import awsExports from './aws-exports';

// Amplify.configure(awsExports);
ReactDOM.render(
  <React.StrictMode>
    {/* <Helmet> */}
    {/*  <meta */}
    {/*    httpEquiv="Content-Security-Policy" */}
    {/*    content="default-src *.sendbird.com; manifest-src 'self'; connect-src 'self' *.sendbird.com; img-src 'self' circular-branding.s3.amazonaws.com  circular-public-dev.s3.amazonaws.com; media-src circular-branding.s3.amazonaws.com; script-src 'self' 'unsafe-inline' script.hotjar.com static.hotjar.com cdn.pendo.io pendo-io-static.storage.googleapis.com; style-src 'self' 'unsafe-inline' fonts.googleapis.com; object-src 'none'; font-src fonts.gstatic.com; frame-src vars.hotjar.com;" */}
    {/*  /> */}
    {/* </Helmet> */}
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
