import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import qs from 'qs';
import './less/App.less';
import ChangeLog from './components/changelog/ChangeLog';

function App() {
  const { search } = window.location;
  const { level } = qs.parse(search, { ignoreQueryPrefix: true });

  return (
    <div className="app">
      <Router>
        <Routes>
          <Route
            path="/changelog/:owner/:repo/:branch"
            element={<ChangeLog />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
